import { useState, useEffect } from 'react';

import { trackPageView } from 'analytics/Mixpanel';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';

import ChronologyListItem from './ListItem';
import { ChronosChronology } from '../../../../../types';
import Pagination from '../../../Pagination';
import DataViewToolbar from '../../components/DataViewToolbar';
import SearchBox from '../../components/SearchBox';
import { useGeneralPagination } from '../../hooks/useGeneralPagination';
interface ChronologyListProps {
  caseId: string | null;
  chronologies: ChronosChronology[];
  isLoadingChronologies: boolean;
  chronologiesSearchFilter: string;
  refetchCaseChronologies: () => void;
  setChronologiesSearchFilter: (searchTerm: string) => void;
}

const ChronologyList = ({
  caseId,
  chronologies,
  setChronologiesSearchFilter,
  refetchCaseChronologies,
  chronologiesSearchFilter,
  isLoadingChronologies,
}: ChronologyListProps) => {
  const [chronologiesListDeleteMap, setChronologiesListDeleteMap] = useState<Record<string, boolean>>({});
  // React Router hooks
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialPageSize = 20;
  const { pagination, setPagination } = useGeneralPagination({ pageSize: initialPageSize });
  const noOfPages = Math.ceil(chronologies.length / (pagination.pageSize || initialPageSize));

  const onSearchCall = () => {
    refetchCaseChronologies();
  };

  const allDeleted = Object.keys(chronologiesListDeleteMap).length === chronologies.length;
  const allRemoved = chronologies.every((chronology) => chronology.is_removed);

  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: initialPageSize };
    setPagination(newPaginationState);
    searchParams.set('page', String(newPaginationState.pageIndex + 1));
    navigate(location.pathname + '?' + searchParams.toString());
  };

  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);
  const canGetPrevPage = pagination.pageIndex > 0;
  const canGetNextPage = pagination.pageIndex < noOfPages - 1;

  // Calculate the start and end index for the current page
  const startIndex = pagination.pageIndex * pagination.pageSize;
  const endIndex = startIndex + pagination.pageSize;
  const currentPageChronologies = chronologies.slice(startIndex, endIndex);

  useEffect(() => {
    trackPageView('Chronology List Page');
  }, []);

  return (
    <div className="flex flex-col h-full">
      <DataViewToolbar
        titleSection={
          <>
            <h1 className="text-lg font-semibold">Chronologies</h1>
            <div className="text-xs font-normal border pl-2 pr-1 bg-gray-50 border-brandSecondary rounded border-opacity-60 flex items-center gap-1 w-fit">
              <span>
                <b>{chronologies.length}</b> chronologies
              </span>
            </div>
          </>
        }
        searchBar={
          <SearchBox
            value={chronologiesSearchFilter}
            placeholder={'Search chronologies'}
            onChange={setChronologiesSearchFilter}
            onSearchCall={onSearchCall}
          />
        }
        pagination={
          <Pagination
            canGetPrevPage={canGetPrevPage}
            canGetNextPage={canGetNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={pagination.pageIndex}
            noOfPages={noOfPages}
            isLoadingFacts={isLoadingChronologies}
            goToPage={goToPage}
          />
        }
      />
      <div className="flex-grow overflow-hidden">
        <div className="h-full overflow-y-auto">
          {isLoadingChronologies ? (
            <div className="w-full h-24 flex items-center justify-center">
              <StageSpinner className="m-auto" color={'#4161FF'} size={25} />
            </div>
          ) : currentPageChronologies.length && !allDeleted && !allRemoved ? (
            <div className="max-w-full pr-2">
              {currentPageChronologies
                .filter((chronology: ChronosChronology) => !chronology.is_removed)
                .map((chronology: ChronosChronology) => {
                  return (
                    <ChronologyListItem
                      chronology={chronology}
                      key={chronology.chronology_id}
                      setChronologiesListDeleteMap={setChronologiesListDeleteMap}
                      chronologiesListDeleteMap={chronologiesListDeleteMap}
                    />
                  );
                })}
            </div>
          ) : (
            <div className="w-full items-center justify-center flex flex-col">
              <div className="mb-5 opacity-60 mt-28">No chronologies found</div>
              <Link
                to={`/app/chronos/case-editor/data/facts?caseId=${caseId}`}
                className="cursor-pointer flex flex-row justify-center items-center h-12 w-36 bg-buttonPrimary rounded-md disabled:opacity-20 shrink-0 text-center text-base not-italic text-white disabled:cursor-not-allowed px-6 py-4"
              >
                Create Now
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChronologyList;
