import { useCallback, useEffect, useRef, useState } from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { trackPageView } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Modal from 'components/molecules/Modals/Settings';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { ChronosChronologyEvent, ChronosDoc } from 'types';

import ChronologyEventAdder from '../ChronologyEventAdder';
import ChronologyTableToolbar from '../ChronologyTableToolbar';
import ChronologyTimeline from '../ChronologyTimeline';
import useChronologyDownload from '../hooks/useChronologyDownload';

const sortByEntryOrder = (a: ChronosChronologyEvent, b: ChronosChronologyEvent) => {
  return a.entry_order - b.entry_order;
};

const ChronologyEditor = () => {
  // State
  const [chronology, setChronology] = useState<ChronosChronologyEvent[]>([]);
  const [totalNumberOfFacts, setTotalNumberOfFacts] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [globalFilter, setGlobalFilter] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string>('');
  const [docs, setDocs] = useState<ChronosDoc[]>([]);
  const page = searchParams.get('page');
  const chronologyId = searchParams.get('chronologyId') || '';
  const chronologyTitle = searchParams.get('title') || '';
  const caseId = searchParams.get('caseId');
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: page ? Number(page) - 1 : 0,
    pageSize: 100,
  });

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchConfigGET } = useGetFetchConfig();

  // ----- Data fetching -----
  // Fetch chronology
  const {
    data: responseChronology,
    isFetching: isLoadingChronologyTable,
    refetch: refetchCaseChronology,
  } = useQuery(
    ['userChronology', chronologyId],
    () => {
      if (chronologyId) {
        return fetch(
          `${APIBaseChronos}/client/case/chronology/${chronologyId}?page=${pageIndex + 1}&pageSize=${pageSize}`,
          fetchConfigGET,
        ).then((res) => {
          return res.json() as Promise<{ chronology: ChronosChronologyEvent[]; totalNumberOfFacts: number }>;
        });
      }
    },
    {
      enabled: false,
    },
  );

  // Fetch docs
  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(
    ['userDocs', caseId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
    },
  );

  const { fetchDownload, isLoadingDownload } = useChronologyDownload({
    chronologyId,
    caseId,
    chronologyTitle,
  });

  // Effects
  useEffect(() => {
    refetchCaseChronology();
    refetchCaseDocs();
    // eslint-disable-next-line
  }, [chronologyId, pageIndex]);

  useEffect(() => {
    trackPageView('Chronology Editor');
  }, []);

  useEffect(() => {
    if (responseChronology?.chronology && responseChronology?.chronology?.length > 0) {
      const orderedChronology = responseChronology?.chronology.sort(sortByEntryOrder);
      setChronology(orderedChronology);
      setTotalNumberOfFacts(responseChronology?.totalNumberOfFacts);
    }
  }, [responseChronology]);

  useEffect(() => {
    if (responseDocs?.docs && responseDocs?.docs?.length > 0) {
      setDocs(responseDocs.docs);
    }
  }, [responseDocs]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const res = await fetch(`${APIBaseChronos}/client/user/user`, fetchConfigGET);
        if (!res.ok) throw new Error('Network response was not ok');
        const data = await res.json();
        setCurrentUserId(data.user.userId);
      } catch (error) {
        console.error('Error fetching current user ID:', error);
      }
    };

    fetchCurrentUser();
  }, [fetchConfigGET]);

  // Handlers
  const handleBack = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('chronologyId');
    navigate('/app/chronos/case-editor/data/chronology?' + searchParams.toString(), { replace: true });
  }, [location.search, navigate]);

  const openModal = useCallback(() => setModalIsOpen(true), []);
  const closeModal = useCallback(() => setModalIsOpen(false), []);

  const handleNewFactCreated = () => {
    setChronology([]);
    refetchCaseChronology();
    closeModal();
  };

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pageIndex - 1);
  const nextPage = () => goToPage(pageIndex + 1);
  const totalPages = Math.ceil((totalNumberOfFacts || 0) / pageSize);

  const onSearchCall = () => {
    //setAppliedGlobalFilter(globalFilter);
  };

  return (
    <>
      {chronologyId && (
        <div className="flex flex-col h-full">
          <ChronologyTableToolbar
            globalFilter={globalFilter}
            chronologyTitle={chronologyTitle}
            isLoadingDownload={isLoadingDownload}
            setGlobalFilter={setGlobalFilter}
            wrapperRef={wrapperRef}
            fetchDownload={fetchDownload}
            handleBack={handleBack}
            openModal={openModal}
            canGetPrevPage={pageIndex > 0}
            canGetNextPage={pageIndex < totalPages}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={pageIndex}
            noOfPages={totalPages}
            goToPage={goToPage}
            onSearchCall={onSearchCall}
          />
          <div className="flex-grow overflow-hidden mb-4 pt-4">
            {isLoadingChronologyTable && (
              <div className="w-full h-24 flex items-center justify-center">
                <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
              </div>
            )}
            {!isLoadingChronologyTable && chronology && chronology.length > 0 && (
              <div className="h-full overflow-y-scroll">
                <ChronologyTimeline chronology={chronology} currentUserId={currentUserId} />
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        title={
          <div className="flex gap-2 items-center font-semibold text-blue-500">
            <FontAwesomeIcon icon={faPlusCircle} className="" />
            Add Fact
          </div>
        }
        isOpen={modalIsOpen}
        handleClose={closeModal}
        content={
          <ChronologyEventAdder
            docs={docs}
            handleNewFactCreated={handleNewFactCreated}
            chronology_id={chronologyId || ''}
          />
        }
        size="small"
      />
    </>
  );
};

export default ChronologyEditor;
