import { faChevronLeft, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Viewer } from '@react-pdf-viewer/core';
import { HighlightArea, highlightPlugin, RenderHighlightsProps, Trigger } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

interface HighlightAreaWithFactId extends HighlightArea {
  factId: string;
}

interface PDFViewerProps {
  fileUrl: string | null | undefined;
  loading: boolean;
  initialPage: number;
  handleClosePDFViewer?: (() => void) | null;
  highlights?: HighlightAreaWithFactId[];
  highlightInstance?: any;
  closeButtonText?: string;
  defaultZoom?: number;
}

const PDFViewer = ({
  fileUrl,
  loading,
  initialPage,
  highlightInstance,
  highlights,
  handleClosePDFViewer,
  closeButtonText = 'Back',
  defaultZoom = 1.1,
}: PDFViewerProps) => {
  let highlightPluginInstance = highlightInstance;
  if (!highlightInstance && highlights) {
    const renderHighlights = (props: RenderHighlightsProps) => {
      return (
        <div>
          {highlights
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                className={`highlight-area bg-yellow-300 opacity-40 z-10 cursor-pointer`}
                style={Object.assign({}, props.getCssProperties(area, props.rotation), { pointerEvents: 'auto' })}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></div>
            ))}
        </div>
      );
    };

    highlightPluginInstance = highlightPlugin({
      renderHighlights: renderHighlights,
      trigger: Trigger.None,
    });
  }

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const { CurrentPageInput, GoToFirstPageButton, GoToLastPageButton, GoToNextPageButton, GoToPreviousPage } =
    pageNavigationPluginInstance;

  return (
    <>
      {loading ? (
        <div className="flex flex-col gap-4 items-center justify-center h-full">
          <span className="text-gray-500 text-lg">Fetching File...</span>
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin w-6 h-6 text-gray-500" />
        </div>
      ) : (
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between border-b shadow-sm">
            <div className="flex items-center justify-between gap-2 w-full pt-1 px-2">
              <div
                className="cursor-pointer flex gap-2 items-center border px-2 bg-gray-50 rounded-full"
                onClick={() => handleClosePDFViewer && handleClosePDFViewer()}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="w-3 h-3" />
                {closeButtonText}
              </div>
              <div className="flex items-center justify-center gap-2">
                <GoToFirstPageButton />
                <GoToPreviousPage />
                <CurrentPageInput />
                <GoToNextPageButton />
                <GoToLastPageButton />
              </div>
              <div className="flex items-center justify-center gap-2">
                <ZoomInButton />
                <ZoomOutButton />
                <ZoomPopover />
              </div>
            </div>
          </div>
          <div className="overflow-hidden pt-2">
            {fileUrl && (
              <Viewer
                fileUrl={fileUrl}
                plugins={[pageNavigationPluginInstance, highlightPluginInstance, zoomPluginInstance]}
                initialPage={initialPage - 1} // package 0 indexes pages
                defaultScale={defaultZoom}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PDFViewer;
