import React, { useRef, useState, useEffect } from 'react';

import { IconDefinition, faChevronDown, faChevronUp, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DropdownOption {
  label: string;
  onClick: () => void;
}

interface DropdownProps {
  id: string;
  buttonLabel: string;
  buttonIcon: IconDefinition;
  options: DropdownOption[];
  isDisabled?: boolean;
  isLoading?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  id,
  buttonLabel,
  buttonIcon,
  options,
  isDisabled = false,
  isLoading = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        // Need to do this so that the create chronology tour works when clicking next on tour
        let element = event.target as HTMLElement;
        let hasDataAttr = false;
        for (let i = 0; i < 4 && element; i++) {
          // Check up to 3 parents (0 is the target itself)
          if (element.hasAttribute('data-tour-elem')) {
            hasDataAttr = true;
            break;
          }
          element = element.parentElement as HTMLElement;
        }
        if (!hasDataAttr) {
          setDropdownOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const maxHeight = dropdownOpen ? `${dropdownContentRef.current?.scrollHeight}px` : '0px';

  return (
    <div data-open={dropdownOpen} className="relative" ref={dropdownRef}>
      <button
        id={`${id}-dropdown`}
        className="bg-buttonSecondary hover:bg-buttonSecondary-hover border transition-transform duration-200 shadow text-xs flex justify-center items-center px-2 py-2 rounded not-italic cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-500 disabled:opacity-20"
        disabled={isDisabled || isLoading}
        onClick={() => !isDisabled && toggleDropdown()}
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin w-28 py-1" />
        ) : (
          <>
            <FontAwesomeIcon icon={buttonIcon} className="pr-2" />
            {buttonLabel}
            <FontAwesomeIcon icon={dropdownOpen ? faChevronUp : faChevronDown} className="pl-1" />
          </>
        )}
      </button>

      <div
        id={`${id}-dropdown-options`}
        ref={dropdownContentRef}
        style={{
          maxHeight,
          overflow: 'hidden',
          transition: 'max-height 0.5s ease',
        }}
        className="text-xs absolute rounded-b-md shadow-lg w-full z-10 bg-white"
      >
        {options.map((option, index) => (
          <button
            key={index}
            className="flex justify-center items-center px-2 py-3 not-italic w-full cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
            onClick={option.onClick}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
