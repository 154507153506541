import Lottie, { ILottie } from '@lottielab/lottie-player/react';
import KimLoadingAnimation from 'assets/animations/KimLoading.json';

import InfoPanel from './components/InfoPanel';
import QuestionInput from './components/QuestionInput';

interface QuestionProps {
  loading: boolean;
  lottieRef: React.RefObject<ILottie>;
  onExecuteCall: () => void;
  setQuestionValue: (v: string) => void;
  questionValue: string;
}

const Question = ({ loading, lottieRef, onExecuteCall, setQuestionValue, questionValue }: QuestionProps) => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center relative overflow-hidden">
      {loading ? null : <div className="text-2xl font-bold mt-20 text-gray-600">Explore the facts...</div>}
      {/* Container for Lottie and QuestionInput */}
      <div
        className={`relative w-full h-full flex items-center justify-center transition-all duration-300 ${
          loading ? 'mt-[300px]' : ''
        }`}
      >
        <div
          className={`absolute left-0 right-0 bottom-0 top-0 mb-[70px] transition-opacity duration-75 flex items-center justify-center ${
            loading ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <Lottie
            lottie={KimLoadingAnimation}
            ref={lottieRef}
            autoplay={true}
            loop={false}
            className="w-[690px] h-[500px]"
          />
        </div>
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center transition-opacity duration-75 ${
            loading ? 'opacity-0' : 'opacity-100'
          }`}
        >
          <QuestionInput
            onExecuteCall={onExecuteCall}
            onChange={(v: string) => setQuestionValue(v)}
            value={questionValue}
          />
        </div>
      </div>
      <div className={`w-full transition-all duration-300 ${loading ? 'translate-y-full' : ''}`}>
        <InfoPanel />
      </div>
    </div>
  );
};

export default Question;
