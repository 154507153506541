import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as CalendarEdit } from 'assets/calendar-edit.svg';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { ReactComponent as ReactInfoYellow } from 'assets/info_yellow.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';
import { ChronosFact } from 'types';

const DocViewerFact = ({ fact }: { fact: ChronosFact }) => {
  const RelevanceIndicator = ({ importance }: { importance: string }) => {
    const getRelevanceInfo = () => {
      switch (importance) {
        case 'Yes':
          return { text: 'Relevant', color: 'text-green-800', icon: faCircleCheck };
        case 'May be relevant':
          return { text: 'Maybe Relevant', color: 'text-orange-400', icon: faCircleQuestion };
        default:
          return { text: 'Not Relevant', color: 'text-red-800', icon: faCircleXmark };
      }
    };

    const { text, color, icon } = getRelevanceInfo();

    return (
      <p className={`text-xs w-32 flex justify-end items-center gap-2 ${color}`}>
        <FontAwesomeIcon icon={icon} />
        <span>{text}</span>
      </p>
    );
  };
  return (
    <div className="flex items-center justify-between font-normal w-full ">
      <div className={`px-2 pl-2 w-full p-2`}>
        <div className="flex justify-between w-full">
          <div className={`font-semibold flex gap-2 items-center text-xs`}>
            <CalendarEdit className="h-3 w-3" />
            {fact.date_of_subject_text ? fact.date_of_subject_text : '(Date Unknown)'}

            {!!fact.date_of_subject_is_ambiguous && (
              <>
                <ReactInfoYellow
                  data-tooltip-id={`fact-date-text-tooltip-${fact.event_id}-ambiguous`}
                  data-tooltip-content={
                    fact.date_of_subject_is_ambiguous_text || 'Date format is ambiguous, please check'
                  }
                  className="text-gray-700 text-sm cursor-pointer"
                  style={{ color: '#E3B336' }}
                />
                <Tooltip
                  opacity={1}
                  id={`fact-date-text-tooltip-${fact.event_id}-ambiguous`}
                  style={TooltipStyles}
                  place={'top-start'}
                />
              </>
            )}
            {!!fact.is_future_date && !fact.date_of_subject_is_ambiguous && (
              <>
                <ReactInfo
                  data-tooltip-id={`fact-date-text-tooltip-${fact.event_id}`}
                  data-tooltip-content="Hypothetical future date"
                  className="text-gray-700 text-sm cursor-pointer"
                  style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
                />
                <Tooltip opacity={1} id={`fact-date-text-tooltip-${fact.event_id}`} style={TooltipStyles} />
              </>
            )}
          </div>
          <RelevanceIndicator importance={fact.important} />
        </div>

        <div className="flex justify-between gap-1">
          <div className="w-full text-gray-900 pt-1 text-xs">
            {fact.action_described.length > 70 ? fact.action_described.slice(0, 70) + '...' : fact.action_described}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocViewerFact;
