import React, { Dispatch, SetStateAction, useRef, useState, useEffect } from 'react';

import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

interface TextInputCellProps {
  entryId: string;
  entryFieldKey: string;
  entryFieldValue: string;
  endpointToUpdate: string;
  updateState?: (val: string) => void;
  editable?: boolean;
  className?: string;
  setIsDateDirty?: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
  location?: string;
}

const TextInput = ({
  entryId,
  entryFieldKey,
  entryFieldValue,
  endpointToUpdate,
  updateState,
  editable,
  className = '',
  setIsDateDirty,
  callback,
  location = '',
}: TextInputCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFieldValue, setCurrentFieldValue] = useState(entryFieldValue || '');
  const { getFetchConfig } = useGetFetchConfig();

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const textContentRef = useRef<HTMLDivElement | null>(null);

  const autoResize = () => {
    if (textAreaRef.current && textContentRef.current) {
      const contentHeight = Math.max(textContentRef.current.offsetHeight, 20);
      textAreaRef.current.style.height = `${contentHeight + 5}px`;
    }
  };

  useEffect(() => {
    setCurrentFieldValue(entryFieldValue || '');
  }, [entryFieldValue]);

  useEffect(() => {
    if (editMode) {
      autoResize();
    }
  }, [editMode, currentFieldValue]);

  const { isFetching: isLoadingUpdateFieldValue, refetch: refetchUpdateFieldValue } = useQuery(
    ['updateFieldValue', entryId, entryFieldKey],
    () => {
      setEditMode(false);
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { [entryFieldKey]: currentFieldValue } });
      return fetch(endpointToUpdate, fetchConfig)
        .then((res) => {
          if (entryFieldKey === 'date_of_subject_text' && setIsDateDirty) {
            setIsDateDirty(true);
          }
          if (updateState) updateState(currentFieldValue);
          callback && callback();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error on update',
            text: 'There was an error on updating the fields. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const toggleEditMode = () => {
    if (!editMode) {
      setEditMode(true);
      setTimeout(() => {
        autoResize();
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      }, 0);
    } else {
      refetchUpdateFieldValue();
    }
  };

  const handleChangeFieldValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentFieldValue(e.target.value);
    autoResize();
  };

  return (
    <div className={`flex flex-row items-center justify-start w-full ${className}`}>
      {isLoadingUpdateFieldValue ? (
        <div className="w-full flex justify-center items-center">
          <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
        </div>
      ) : (
        <div className="relative max-w-full">
          <div ref={textContentRef} className={`${editMode ? 'invisible' : ''} whitespace-pre-wrap break-words`}>
            {currentFieldValue}
          </div>
          {editMode && (
            <textarea
              ref={textAreaRef}
              className="absolute top-[-4px] left-[-2px] w-[calc(100%+16px)] rounded-md resize-none overflow-hidden border pb-1"
              onChange={(e) => {
                handleChangeFieldValue(e);
                autoResize();
              }}
              onClick={(e) => e.stopPropagation()}
              value={currentFieldValue}
            />
          )}
        </div>
      )}
      {editMode ? (
        <div
          className="flex justify-center items-center p-2 pl-4"
          onClick={(e) => {
            e.stopPropagation();
            trackCustomEvent(`Field updated ${entryFieldKey} - ${location}`);
            toggleEditMode();
          }}
        >
          <FontAwesomeIcon
            icon={faCheck}
            className="text-gray-700 ml-1 cursor-pointer p-1 bg-gray-200 rounded-full h-3 w-3"
          />
        </div>
      ) : (
        editable &&
        !isLoadingUpdateFieldValue && (
          <span
            className="p-1 pl-2 pr-4"
            onClick={(e) => {
              e.stopPropagation();
              toggleEditMode();
            }}
          >
            <FontAwesomeIcon
              icon={faPencil}
              className="w-3 h-3 transition-all duration-500 hover:scale-125 hover:cursor-pointer hover:text-gray-800"
            />
          </span>
        )
      )}
    </div>
  );
};

export default TextInput;
