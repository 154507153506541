import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface UseChronologyDownloadProps {
  chronologyId: string;
  caseId: string | null;
  chronologyTitle: string;
}

// This should maybe use useMutation?
const useChronologyDownload = ({ chronologyId, caseId, chronologyTitle }: UseChronologyDownloadProps) => {
  const { getFetchConfig } = useGetFetchConfig();

  const downloadChronology = async (format: string) => {
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        format,
        caseId,
      },
    });

    const downloadResult = await fetch(
      `${APIBaseChronos}/client/case/chronology/createAndDownloadDocChronology/${chronologyId}`,
      fetchConfig,
    );

    const blob = await downloadResult.blob();
    const link = document.createElement('a');
    const file = new Blob([blob], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);

    link.download = `${chronologyTitle}.${format}`;
    link.click();

    URL.revokeObjectURL(link.href);
  };

  const { mutate: fetchDownload, isLoading: isLoadingDownload } = useMutation(downloadChronology);

  return { fetchDownload, isLoadingDownload };
};

export default useChronologyDownload;
