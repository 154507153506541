import React from 'react';

import { faBookOpen, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoPanel: React.FC = () => {
  return (
    <div className="flex flex-col gap-6 mx-16 mb-12 text-sm">
      <div className="flex gap-6">
        <div className="border shadow-sm rounded-md px-4 py-2 w-1/3">
          <p className="font-medium border-b mb-2 w-fit pr-8">
            <FontAwesomeIcon icon={faBookOpen} className="text-gray-600 mr-2 w-3 h-3" />
            About
          </p>
          <div className="text-gray-700 text-xs">
            <ul className="list-disc pl-4">
              <li className="my-1">
                Kim is Wexler's Conversational Agent, which allows you to ask questions about the facts and documents in
                your case.
              </li>
              <li className="mt-1">
                Kim only looks at information uploaded in your case, it doesn't search the internet or use any external
                sources. It will cite any relevant facts and/or extracts of source text as evidence in its answers.
              </li>
            </ul>
          </div>
        </div>
        <div className="border shadow-sm rounded-md px-4 py-2 w-2/3">
          <p className="font-medium border-b mb-2 w-fit pr-8">
            <FontAwesomeIcon icon={faLightbulb} className="text-gray-600 mr-2 w-3 h-3" />
            Tips
          </p>
          <div className="text-gray-700 text-xs">
            <ul className="list-disc pl-4">
              <li className="my-1">
                Kim works best on short, focused questions as opposed to questions about overarching themes and topics,
                but it can still answer these!
              </li>
              <li className="mt-1">
                Answers are cited with relevant facts <span className="text-blue-500">[blue]</span> and sections of
                document text <span className="text-orange-500">[orange]</span> which you can follow up with to find
                more information.
              </li>
              <li className="mt-1">
                All answers come with a confidence rating from <b>High - Low</b>, based on Kim's confidence that the
                cited evidence supports its answer, which you can use as a guide on whether you should follow up on the
                answer.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-xs text-gray-500 border shadow-sm rounded-md px-4 py-2">
        At the moment Kim does not support follow-up questions, so if you want to ask a follow-up question, you will
        need to create a new question (support for follow-up questions coming soon).
      </div>
    </div>
  );
};

export default InfoPanel;
