import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';

import { ILottie } from '@lottielab/lottie-player/react';
import { trackPageView } from 'analytics/Mixpanel';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Answer from './Answer';
import useCreateThreadAndMessage from './hooks/useCreateThreadAndMessage';
import useGetMessages from './hooks/useGetMessages';
import useGetThreads from './hooks/useGetThreads';
import Question from './Question';
import ThreadsBar from './ThreadsBar';

const CaseAssistant = React.memo(() => {
  // State
  const [questionValue, setQuestionValue] = useState('');
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const caseId = searchParams.get('caseId');
  const threadId = searchParams.get('threadId');

  // Data fetching
  const { responseThreads, isLoadingThreads, refetchThreads } = useGetThreads(caseId || '');
  const { isLoadingMessages, responseMessages, refetchMessages } = useGetMessages(threadId || '');
  // Mutations
  const { mutate: createThreadAndMessage } = useCreateThreadAndMessage();

  const lottieRef = useRef<ILottie | null>(null);
  const switchState = (stateName: string) => {
    lottieRef.current?.interactivity?.trigger(stateName);
  };

  // Effects
  useEffect(() => {
    refetchThreads();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (threadId) {
      refetchMessages();
    }
    // eslint-disable-next-line
  }, [threadId]);

  // Handlers
  const handleNewThread = useCallback(() => {
    trackCustomEvent('Kim New Thread');
    searchParams.delete('threadId');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }, [navigate, location, searchParams]);

  const goToThread = useCallback(
    (thread_id: string) => {
      searchParams.set('threadId', thread_id);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    [navigate, location, searchParams],
  );

  const { fetchConfigGET } = useGetFetchConfig();

  const handleSuccess = (data: any) => {
    if (data.threadId && data.messageId && data.status === 200) {
      // --------------------SSE---------------------
      // Send the messageId to poll endpoint
      const eventSource = new EventSourcePolyfill(`${APIBaseChronos}/client/case/assistant/poll/${data.messageId}`, {
        headers: Object.fromEntries(fetchConfigGET.headers.entries()),
        heartbeatTimeout: 180000,
      });

      eventSource.onmessage = (event: any) => {
        const message = JSON.parse(event.data);
        // Update UI here based on the data received
        if (
          (message.stage === 'SubquestionIdentifier' || message.stage === 'SearchTypeIdentifier') &&
          message.type === 'task_complete'
        ) {
          switchState('startDocSearch');
        }

        if (
          (message.stage === 'QuestionAnswerer' || message.stage === 'ProcessReferences') &&
          message.type === 'task_start'
        ) {
          switchState('startSynthesise');
        }

        if (
          (message.stage === 'QuestionAnswerer' || message.stage === 'SearchFormatter') &&
          message.type === 'task_complete'
        ) {
          switchState('complete');
          eventSource.close();
          // Wait 1 second before setting loading to false
          setTimeout(() => {
            searchParams.set('threadId', data.threadId);
            navigate(`${location.pathname}?${searchParams.toString()}`);
            setLoading(false);
            refetchThreads();
          }, 1000);
        }
      };

      eventSource.onerror = (error: any) => {
        searchParams.set('threadId', data.threadId);
        navigate(`${location.pathname}?${searchParams.toString()}`);
        setLoading(false);
        eventSource.close();
      };
    } else {
      console.error('No thread_id returned from the server.');
    }
  };

  const onExecuteCall = async () => {
    trackCustomEvent('Kim Question Asked');
    setLoading(true);
    setQuestionValue('');
    switchState('start');
    createThreadAndMessage(
      { caseId: caseId || '', question: questionValue },
      {
        onSuccess: handleSuccess,
        onError: (error) => console.error('Error creating thread and message:', error),
      },
    );
  };

  const threads = useMemo(() => responseThreads?.threads?.reverse(), [responseThreads]);

  trackPageView('Kim');

  return (
    <div className="w-full flex flex-row gap-2 h-[87%]">
      <>
        <div className="flex flex-col w-full border bg-white h-full rounded-md border-opacity-40">
          {threadId ? (
            <Answer
              caseId={caseId || ''}
              threadId={threadId || ''}
              messages={responseMessages?.messages}
              loading={isLoadingMessages}
            />
          ) : (
            <Question
              loading={loading}
              lottieRef={lottieRef}
              onExecuteCall={onExecuteCall}
              setQuestionValue={setQuestionValue}
              questionValue={questionValue}
            />
          )}
        </div>
        <div className="flex flex-col w-44 border bg-white pb-2 rounded-md border-opacity-40">
          <ThreadsBar
            threads={threads}
            createNewThread={handleNewThread}
            goToThread={goToThread}
            isLoadingThreads={isLoadingThreads}
          />
        </div>
      </>
    </div>
  );
});

export default CaseAssistant;
