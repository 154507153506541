import React, { useState, useCallback, useMemo } from 'react';

import { faCirclePlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import { reactSelectStylesFormFact } from 'constants/styles';
import 'react-calendar/dist/Calendar.css';
import { useQuery } from 'react-query';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ChronosDoc, MyOptionType } from 'types';

interface FactAdderProps {
  docs: ChronosDoc[];
  handleNewFactCreated: () => void;
  chronology_id: string;
}
const FactAdder = ({ handleNewFactCreated, docs, chronology_id }: FactAdderProps) => {
  const [selectedDoc, setSelectedDoc] = useState<MyOptionType>(null);
  const [significance, setSignificance] = useState<string>('');
  const { getFetchConfig } = useGetFetchConfig();

  const [description, setDescription] = useState<string>('');
  const [dateOfSubjectMatterText, setDateOfSubjectMatterText] = useState<string>('');

  const fetchConfig = getFetchConfig({
    method: 'POST',
    data: {
      longer_description: description,
      fact_date_text: dateOfSubjectMatterText,
      chronology_id: chronology_id,
      relevance_reason: significance,
    },
  });

  const { isFetching: isLoadingAddNewFact, refetch: refetchAddNewFact } = useQuery(
    ['addFactQuery', selectedDoc?.value],
    () => {
      return fetch(`${APIBaseChronos}/client/case/chronology/${selectedDoc?.value}`, fetchConfig)
        .then((res) => {
          Swal.fire({
            title: 'New fact created succesfully',
            text: 'A new fact was created succesfully',
            showConfirmButton: false,
            timer: 2500,
          });
          handleNewFactCreated();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error while adding new fact',
            text: 'There was an error while adding new fact. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleChangeDescription = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  }, []);

  const handleChangeSelectedDoc = useCallback((val: MyOptionType) => {
    setSelectedDoc(val);
  }, []);

  const handleChangeDateOfSubjectMatterText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfSubjectMatterText(e.target.value);
  }, []);

  const handleChangeSignificance = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSignificance(e.target.value);
  }, []);

  const docsOptions = useMemo(
    () =>
      docs.map((doc) => ({
        value: doc.doc_id,
        label: doc.file_name,
      })),
    [docs],
  );

  const handleAddFact = () => {
    refetchAddNewFact();
  };

  const disabled = !selectedDoc;
  return (
    <>
      {isLoadingAddNewFact ? (
        <div className="flex flex-col justify-center items-center h-72 gap-8">
          <div className="text-xl">Adding Fact...</div>
          <FontAwesomeIcon icon={faCircleNotch} className="text-4xl fa-spin text-brandSecondary" />
        </div>
      ) : (
        <div className="px-6 py-4">
          <input
            onChange={handleChangeDescription}
            className="h-12 pl-3 mt-2 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
            value={description}
            placeholder="Description"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>
          <Select
            options={docsOptions}
            className="outline-none w-full bg-white rounded-md mt-3"
            styles={reactSelectStylesFormFact}
            onChange={handleChangeSelectedDoc}
            value={selectedDoc}
            placeholder={'Select a document'}
          />
          <input
            onChange={handleChangeDateOfSubjectMatterText}
            className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white"
            value={dateOfSubjectMatterText}
            placeholder="Date"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>
          <input
            onChange={handleChangeSignificance}
            className="h-12 pl-3 mt-3 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 rounded-lg bg-white mb-6"
            value={significance}
            placeholder="Significance"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>

          <Button
            onClick={() => {
              !disabled && handleAddFact();
            }}
            text="Add"
            type="primary"
            rounded="md"
            icon={<FontAwesomeIcon icon={faCirclePlus} className="mr-2" />}
          />
        </div>
      )}
    </>
  );
};

export default FactAdder;
