import { MutableRefObject, useCallback } from 'react';

import { faArrowLeft, faDownload, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import Dropdown from 'components/molecules/DropdownButton';

import Pagination, { PaginationProps } from '../../../Pagination';
import DataViewToolbar from '../../components/DataViewToolbar';
// import SearchBox from '../../components/SearchBox';

interface ChronologyTableToolbarProps {
  globalFilter: string;
  chronologyTitle: string;
  isLoadingDownload: boolean;
  wrapperRef: MutableRefObject<HTMLDivElement | null>;
  setGlobalFilter: (value: string) => void;
  fetchDownload: (docType: string) => void;
  handleBack: () => void;
  openModal: () => void;
  goToPage: (value: number) => void;
  onSearchCall: () => void;
}

const ChronologyTableToolbar = ({
  globalFilter,
  setGlobalFilter,
  chronologyTitle,
  wrapperRef,
  isLoadingDownload,
  fetchDownload,
  handleBack,
  openModal,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  goToPage,
  onSearchCall,
}: ChronologyTableToolbarProps & PaginationProps) => {
  const handleDownloadClick = useCallback(() => {
    fetchDownload('csv');
  }, [fetchDownload]);

  return (
    <DataViewToolbar
      titleSection={
        <div className="flex items-center pr-4">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="ml-2 mr-3 cursor-pointer text-gray-700 border rounded-full p-1 bg-gray-50 h-3 w-3"
            onClick={handleBack}
          />
          <div className="text-blue-500 font-semibold">{chronologyTitle}</div>
        </div>
      }
      longTitleSection={true}
      actionButtons={
        <>
          <Button
            icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
            className="bg-buttonSecondary hover:bg-buttonSecondary-hover px-2 py-2 border shadow text-xs rounded"
            onClick={openModal}
            text="Add Fact"
          />

          <div className="relative" ref={wrapperRef}>
            {/* Disable if isLoadingDownload */}
            <Dropdown
              id="chronology-download"
              buttonLabel="Download"
              buttonIcon={faDownload}
              options={[{ label: 'Download Excel', onClick: handleDownloadClick }]}
              isLoading={isLoadingDownload}
            />
          </div>
        </>
      }
      searchBar={
        // TODO: Add back search bar, temporarily removed
        // <SearchBox
        //   value={globalFilter}
        //   placeholder={'Search chronology'}
        //   onChange={setGlobalFilter}
        //   onSearchCall={onSearchCall}
        // />
        <></>
      }
      pagination={
        <Pagination
          canGetPrevPage={canGetPrevPage}
          canGetNextPage={canGetNextPage}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          noOfPages={noOfPages}
          goToPage={goToPage}
        />
      }
    />
  );
};

export default ChronologyTableToolbar;
