import React, { useRef, useEffect } from 'react';

import { ILottie } from '@lottielab/lottie-player/react';
import DownloadAnimation from 'assets/animations/Download.json';

import LottieAnimation from '../Lottie';

interface DownloadProps {
  downloadStart: boolean;
  downloadComplete: boolean;
  handleDownload: () => void;
}

const Download = ({ downloadStart, downloadComplete, handleDownload }: DownloadProps) => {
  const lottieRef = useRef<ILottie | null>(null);

  const switchState = (stateName: string) => {
    lottieRef.current?.interactivity?.trigger(stateName);
  };

  useEffect(() => {
    if (downloadComplete) {
      switchState('complete');
      return;
    }
    if (downloadStart) {
      switchState('start');
    }
  }, [downloadStart, downloadComplete]);

  return (
    <div
      onClick={handleDownload}
      className="flex items-center gap-1 border hover:bg-gray-200 hover:cursor-pointer rounded-full px-3 py-2"
    >
      <LottieAnimation lottieRef={lottieRef} animationData={DownloadAnimation} className="h-5 w-5" />

      <span className="text-xs">Download</span>
    </div>
  );
};

export default Download;
