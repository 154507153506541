import React, { useEffect, useMemo, useState } from 'react';

import { faArrowLeft, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Modal from 'components/molecules/Modals/Settings';
import PageMissing from 'components/molecules/PageMissing';
import { PIPELINE_FREEZE_LOAD_STATUS, PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from 'constants/pipelineStatus';
import 'moment-timezone';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosRun } from 'types';

import CaseAssistant from './CaseAssistant';
import CaseSummary from './CaseSummary';
import NavigationTabs from './components/editor/NavigationTabs';
import StatusDisplay from './components/editor/StatusDisplay';
import SettingsModalContent from './components/Modals/SettingsModal';
import PipelineCompleteOverview from './components/PipelineCompleteOverview';
import DataView from './DataView';
import computeCaseMessage from './helpers/computeCaseMessage';

export interface CaseState {
  caseName: string;
  runs: ChronosRun[];
  casePipelineStatus: string;
  queueEstimateDate: Date;
  inQueue: boolean;
  queuePosition: any; // Change this to the actual type
  matterId: string;
  estimateDate: Date;
  modalIsOpen: boolean;
  runStatusIsOpen: boolean;
}

const CaseEditor: React.FC = () => {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  const [caseState, setCaseState] = useState<CaseState>({
    caseName: '',
    runs: [],
    casePipelineStatus: '',
    queueEstimateDate: new Date(),
    inQueue: false,
    queuePosition: null,
    matterId: '',
    estimateDate: new Date(),
    modalIsOpen: false,
    runStatusIsOpen: false,
  });
  const navigate = useNavigate();
  const { fetchConfigGET } = useGetFetchConfig();

  const { isFetching: isLoadingCase, refetch: refetchCase } = useQuery(
    'currentCase',
    () => fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET).then((res) => res.json()),
    {
      cacheTime: 0,
      enabled: !PIPELINE_FREEZE_LOAD_STATUS.includes(caseState.casePipelineStatus),
      refetchInterval: 3000,
      onSuccess: (response) => {
        if (response?.type === 'unauthorized') {
          Swal.fire({
            title: '',
            text: 'Unauthorized. You are being redirected to the matters explorer',
            showConfirmButton: false,
            timer: 3000,
          });
          navigate(`/app/chronos/matters`);
        } else if (response?.caseObject?.case_id) {
          setCaseState((prevState) => ({
            ...prevState,
            caseName: response.caseObject.case_name,
            runs: response.runs,
            casePipelineStatus: response.runs[0].pipeline_status,
            queueEstimateDate: response.runs[0].queue_estimate_datetime,
            inQueue: response.runs[0].in_queue,
            queuePosition: response.runs[0].queue_position || null,
            estimateDate: response.runs[0].time_estimate_datetime,
            matterId: response.caseObject.matter_id,
          }));
        }
      },
    },
  );

  useEffect(() => {
    refetchCase();
    // eslint-disable-next-line
  }, []);

  const handleClickBack = () => {
    navigate(`/app/chronos/explore?matterId=${caseState.matterId}`);
  };

  const message = useMemo(
    () =>
      computeCaseMessage(
        caseState.inQueue,
        caseState.queuePosition,
        caseState.estimateDate,
        caseState.queueEstimateDate,
      ),
    [caseState.inQueue, caseState.queuePosition, caseState.estimateDate, caseState.queueEstimateDate],
  );

  const handleClickStatusView = () => {
    setCaseState((prev) => ({ ...prev, runStatusIsOpen: !prev.runStatusIsOpen }));
  };

  const runsInProgress = caseState.runs.filter((run: ChronosRun) => {
    return ![PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(run.pipeline_status);
  });

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full h-full text-black overflow-y-scroll">
        <div className="w-full h-full pt-4 bg-center pb-2 px-6">
          {/* Still processing case */}
          {PIPELINE_PROCESSING_STATUS.includes(caseState.casePipelineStatus) ||
          caseState.casePipelineStatus === PIPELINE_STATUS.failed.value ? (
            <>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer"
                onClick={handleClickBack}
              />
              <StatusDisplay casePipelineStatus={caseState.casePipelineStatus} message={message} />
            </>
          ) : isLoadingCase ? (
            <div className="w-full flex flex-col items-center justify-center h-full">
              {/* Loading Case */}
              <StageSpinner className="m-auto" size={40} color={'#4161FF'} />
            </div>
          ) : [PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(caseState.casePipelineStatus) ? (
            <div className="w-full h-full">
              {/* Case loaded  and pipeline successful*/}
              <div className="w-full flex flex-row justify-between items-center">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="mr-4 text-black w-5 h-5 cursor-pointer"
                    onClick={() => handleClickBack()}
                  />
                  <p className="w-auto focus:outline-none flex items-center text-black text-xl not-italic font-bold placeholder:text-gray-400">
                    {caseState.caseName}
                    <FontAwesomeIcon
                      icon={faCog}
                      className="w-4 h-4 p-2 text-brandPrimary rounded-full hover:cursor-pointer hover:text-brandPrimary-hover"
                      onClick={() => setSettingsModalOpen(true)}
                    />
                  </p>
                </div>
                <PipelineCompleteOverview
                  handleClickStatusView={handleClickStatusView}
                  caseState={caseState}
                  totalRuns={runsInProgress.length}
                />
              </div>
              {caseId && <NavigationTabs caseId={caseId} />}
              <Routes>
                <Route path="summary" element={<CaseSummary />} />
                <Route path="data/*" element={<DataView openSettingsModal={() => setSettingsModalOpen(true)} />} />
                <Route path="assistant" element={<CaseAssistant />} />
                <Route path="*" element={<Navigate to={`/app/chronos/case-editor/summary`} />} />
              </Routes>
            </div>
          ) : (
            //Add generic message here for unable to load, use same one as the case summary one
            <PageMissing
              title={'Case Loading Failed'}
              description={
                <div className="text-sm mt-4">
                  Sorry! We encounterd a problem loading this case. Please{' '}
                  <a className="text-blue-500 hover:cursor-pointer" href="mailto:support@wexler.ai">
                    contact
                  </a>{' '}
                  Wexler for support.
                </div>
              }
            />
          )}
        </div>
        <Modal
          title={
            <div className="flex gap-4 items-center">
              <FontAwesomeIcon icon={faCog} className="text-gray-700" />
              Case Settings
            </div>
          }
          isOpen={settingsModalOpen}
          handleClose={() => setSettingsModalOpen(false)}
          content={<SettingsModalContent caseId={caseId as string} handleClose={() => setSettingsModalOpen(false)} />}
        />
      </div>
    </>
  );
};

export default CaseEditor;
