import { useCallback, useEffect, useRef, useState } from 'react';

import {
  faCircleNotch,
  faListCheck,
  faMagnifyingGlass,
  faPlusCircle,
  faQuestionCircle,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { highlightPlugin, RenderHighlightsProps, Trigger } from '@react-pdf-viewer/highlight';
import { trackPageView } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import Modal from 'components/molecules/Modals/Settings';
import ToastDrawer from 'components/molecules/ToastDrawer';
import { motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { ChronosFact } from 'types';
import { ChronosDocumentCoordinate, Coordinate, HighlightCoordinate } from 'types';

import PDFViewer from '../../../PDFViewer';
import useDocumentFacts from '../../DocumentsEditor/hooks/useFetchDocumentFacts';
import FactAdder from '../../FactsEditor/FactsEditorToolbar/components/FactAdder';
import DocViewerFact from '../components/DocViewerFact';
import DocViewerFullFact from '../components/DocViewerFullFact';

interface FactPosition {
  pageIndex: number;
  top: number;
  coordinates: HighlightCoordinate;
  index?: number;
}

const DocViewer = ({
  caseId,
  docId,
  pageNumber,
  factTotal,
}: {
  caseId: string;
  docId: string;
  pageNumber: number;
  factTotal: string | null;
}) => {
  // State
  const navigate = useNavigate();
  const location = useLocation();

  const viewerRef = useRef(null);

  // State for managing virtualised list
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<List>(null);

  const [facts, setFacts] = useState<Array<ChronosFact | { event_id: string }>>([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loadingFile, setLoadingFile] = useState(true);
  const [minPage, setMinPage] = useState(1);
  const [highlightCoordinates, setHighlightCoordinates] = useState<HighlightCoordinate[]>([]);
  const [highlightedFactId, setHighlightedFactId] = useState<string | null>(null);
  const [selectedFactId, setSelectedFactId] = useState<string | null>(null);
  const [factPositions, setFactPositions] = useState<Map<string, FactPosition>>(new Map());
  const [selectedFact, setSelectedFact] = useState<ChronosFact>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [dataDirty, setDataDirty] = useState(false);

  const [factAdderModalIsOpen, setFactAdderModalIsOpen] = useState(false);

  const { fetchConfigGET } = useGetFetchConfig();

  // Data fetching -------------
  const getDocumentUrl = useCallback(async () => {
    if (!docId) return null; // Early exit if no docId provided

    try {
      const downloadResult = await fetch(`${APIBaseChronos}/client/case/doc/download/${docId}`, fetchConfigGET);
      if (!downloadResult.ok) throw new Error('Download failed');

      const blob = await downloadResult.blob();
      const fileBlobUrl = URL.createObjectURL(blob);
      // Consider where and how to call URL.revokeObjectURL(fileBlobUrl) for cleanup
      return fileBlobUrl;
    } catch (error) {
      console.error(error);
      return null; // Consider a structured error handling approach
    }
  }, [docId, fetchConfigGET]); // Add all dependencies here

  const { refetch: fetchDocument } = useQuery(['downloadDoc', docId], getDocumentUrl, { enabled: false });

  const { data: responseFacts, isLoading, isFetching, refetch: refetchCaseFacts } = useDocumentFacts(docId, caseId);

  const getRefCoords = () => {
    if (docId) {
      return fetch(`${APIBaseChronos}/client/case/fact/coordinatesByDocId/${docId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    }
  };

  const { data: responseCoordinates } = useQuery({
    queryKey: ['referenceCoordinates', docId],
    queryFn: getRefCoords,
  });
  //------------------------------------------------------

  // Effects
  useEffect(() => {
    refetchCaseFacts();
    // eslint-disable-next-line
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedFactId(null);
  };
  useEffect(() => {
    if (selectedFactId && responseFacts) {
      const factIndex = factPositions.get(selectedFactId)?.index;
      if (factIndex === undefined) return;
      const newSelectedFact = facts[factIndex];

      // If there is already a selectect fact and drawer is open, close the drawer wait 1s and open it again with the new fact
      if (selectedFact && isDrawerOpen) {
        setIsDrawerOpen(false);
        setTimeout(() => {
          setSelectedFact(newSelectedFact as ChronosFact);
          setIsDrawerOpen(true);
        }, 500);
      } else {
        setSelectedFact(newSelectedFact as ChronosFact);
        setIsDrawerOpen(true);
      }
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedFactId, responseFacts, factPositions, isDrawerOpen, selectedFact, facts]);

  useEffect(() => {
    if (docId) {
      setLoadingFile(true);
      fetchDocument().then(({ data: documentUrl }) => {
        if (documentUrl && typeof documentUrl === 'string') {
          setFileUrl(documentUrl);
        }
        setLoadingFile(false);
      });
    }
    // eslint-disable-next-line
  }, [docId]);

  // Coordinate processing
  useEffect(() => {
    if (responseCoordinates && docId) {
      setMinPage(responseCoordinates.minPage);

      const coordinates =
        responseCoordinates?.factsCoordinates
          ?.filter((coordinate: ChronosDocumentCoordinate) => coordinate.doc_id === docId)
          ?.flatMap((coordinate: ChronosDocumentCoordinate) =>
            coordinate.coordinate_details.map((coordinate_detail: Coordinate) => ({
              eventId: coordinate.event_id,
              height: coordinate_detail.height_c * 100,
              left: coordinate_detail.left_c * 100,
              pageIndex: coordinate_detail.n_page - 1,
              top: coordinate_detail.top_c * 100,
              width: coordinate_detail.width_c * 100,
            })),
          ) || [];
      setHighlightCoordinates(coordinates);
    }
  }, [responseCoordinates, docId]);

  // Create fact -> coordinate mapping
  useEffect(() => {
    if (highlightCoordinates.length > 0) {
      const factPositions = new Map<string, FactPosition>();

      for (const coordinate of highlightCoordinates) {
        if (
          !factPositions.has(coordinate.eventId) ||
          factPositions.get(coordinate.eventId)!.pageIndex > coordinate.pageIndex
        ) {
          factPositions.set(coordinate.eventId, {
            pageIndex: coordinate.pageIndex,
            top: coordinate.top,
            coordinates: coordinate,
          });
        }
      }

      setFactPositions(factPositions);
    }
  }, [highlightCoordinates]);

  // Processing on facts
  useEffect(() => {
    if (responseFacts && docId) {
      const facts = [];
      let markerAdded = false;
      for (const [index, fact] of responseFacts.facts.entries()) {
        if (factPositions.has(fact.event_id)) {
          factPositions.get(fact.event_id)!.index = index;
          facts.push(fact);
        } else {
          if (!markerAdded) {
            facts.push({ event_id: 'marker' });
            markerAdded = true;
          }
          facts.push(fact);
        }
      }
      setFacts(facts);
    }
  }, [responseFacts, docId, factPositions]);

  // Event handlers
  const handleCloseDocDetailView = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('docId');
    searchParams.delete('docPageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [navigate, location.pathname, location.search]);

  const handleNewFactCreated = useCallback(() => {
    // Maybe we don't want to fetch eveyrthing here
    refetchCaseFacts();
    setFactAdderModalIsOpen(false);
    // eslint-disable-next-line
  }, [refetchCaseFacts]);

  // Highlight Handlers
  const scrollToFact = (factId: string) => {
    const factPosition = factPositions.get(factId);
    if (factPosition && factPosition.index && listRef.current) {
      listRef.current.scrollToItem(factPosition.index, 'center');
    }
  };

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        {highlightCoordinates
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className={`highlight-area ${
                area.eventId === highlightedFactId ? 'bg-brandSecondary' : 'bg-yellow-300'
              } opacity-40 z-10 cursor-pointer`}
              style={Object.assign({}, props.getCssProperties(area, props.rotation), { pointerEvents: 'auto' })}
              onClick={(e) => {
                e.stopPropagation();
                setHighlightedFactId(area.eventId);
                scrollToFact(area.eventId);
              }}
            ></div>
          ))}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const { jumpToHighlightArea } = highlightPluginInstance;

  const handleJumpToHighlightArea = useCallback(
    (factId: string) => {
      const factPosition = factPositions.get(factId);
      if (factPosition) {
        jumpToHighlightArea(factPosition.coordinates);
        setHighlightedFactId(factId);
      }
    },
    // eslint-disable-next-line
    [jumpToHighlightArea],
  );

  const handleFactUpdated = () => {
    setDataDirty(true);
  };

  trackPageView('Doc viewer');

  const Row = ({ index, style }: ListChildComponentProps) => {
    const fact = facts[index];
    if (!fact) return null;

    if (fact.event_id === 'marker') {
      return (
        <div style={style}>
          <div className="flex items-center gap-2 mt-8 px-4">
            <div className="border-b flex-grow h-0"></div>
            <div className="text-gray-500 whitespace-nowrap text-sm">Could Not Highlight Text</div>
            <div className="border-b flex-grow h-0"></div>
          </div>
        </div>
      );
    }

    return (
      <div style={style} className="flex items-center gap-2 py-2">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="h-3 w-3 border rounded-full shadow p-2 cursor-pointer hover:bg-gray-100 flex-shrink-0"
          onClick={() => handleJumpToHighlightArea(fact.event_id)}
        />
        <div
          id={`fact-${fact.event_id}`}
          className={`flex-grow bg-white border rounded-md shadow-sm transition-all duration-300 ease-in-out hover:bg-gray-100 cursor-pointer ${
            highlightedFactId === fact.event_id ? 'border-brandSecondary' : ''
          }`}
          onClick={() => {
            setSelectedFactId(fact.event_id);
            handleJumpToHighlightArea(fact.event_id);
          }}
        >
          {<DocViewerFact fact={fact as ChronosFact} />}
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-full">
      <div className="overflow-hidden w-7/12">
        {fileUrl === 'download-error' ? (
          'File cannot be displayed, please try again later.'
        ) : (
          <div ref={viewerRef} className="h-full">
            <PDFViewer
              fileUrl={fileUrl}
              loading={loadingFile}
              highlightInstance={highlightPluginInstance}
              initialPage={pageNumber || minPage}
              handleClosePDFViewer={handleCloseDocDetailView}
              closeButtonText="Back"
              defaultZoom={1.1}
            />
          </div>
        )}
      </div>

      <div className="w-5/12 border-l bg-gray-50 border-gray-300 flex flex-col">
        <div className="font-medium border-b pt-3 pb-2 pl-4 shadow-sm bg-white flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faListCheck} />
            Facts <span className="text-gray-500 text-sm">{factTotal ? `(${factTotal})` : ''}</span>
          </div>
          <div className="flex items-center gap-2">
            {dataDirty && (
              <Button
                icon={<FontAwesomeIcon icon={faRefresh} className="pr-2" />}
                text="Refresh"
                onClick={() => {
                  refetchCaseFacts();
                  setDataDirty(false);
                }}
                type="primary"
                size="small"
                className="bg-buttonSecondary hover:bg-buttonSecondary-hover px-2 py-2 border shadow text-xs rounded"
              />
            )}
            <Button
              icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
              text="Add Fact"
              onClick={() => setFactAdderModalIsOpen(true)}
              type="primary"
              size="small"
              className="bg-buttonSecondary hover:bg-buttonSecondary-hover px-2 py-2 border shadow text-xs rounded"
            />
          </div>
        </div>
        <div id="document-facts-container" className="flex-1 overflow-y-auto" ref={containerRef}>
          <div className="flex flex-col gap-2 pl-2 pr-1 bg-gray-50 pt-2 py-1 pb-3 h-full">
            {isLoading || isFetching ? (
              <div className="flex items-center justify-center h-full">
                <FontAwesomeIcon icon={faCircleNotch} className="fa-spin w-4 h-4" />
              </div>
            ) : facts.length > 0 ? (
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                  <List ref={listRef} height={height} itemCount={facts.length} itemSize={72} width={width}>
                    {Row}
                  </List>
                )}
              </AutoSizer>
            ) : (
              <div className="flex flex-col gap-2 items-center justify-center h-full">
                <FontAwesomeIcon icon={faQuestionCircle} className="w-6 h-6 text-gray-500" />
                <span className="text-gray-500 text-sm">Could not find any facts in this document.</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer}>
        {selectedFact && (
          <motion.div key={selectedFact.event_id}>
            <DocViewerFullFact fact={selectedFact} handleFactUpdated={handleFactUpdated} />
          </motion.div>
        )}
      </ToastDrawer>

      <Modal
        title={
          <div className="flex gap-2 items-center font-semibold text-blue-500">
            <FontAwesomeIcon icon={faPlusCircle} className="" />
            Add Fact
          </div>
        }
        content={<FactAdder docs={[]} handleNewFactCreated={handleNewFactCreated} docId={docId} />}
        isOpen={factAdderModalIsOpen}
        size="small"
        handleClose={() => setFactAdderModalIsOpen(false)}
      />
    </div>
  );
};

export default DocViewer;
