import { faCheckCircle, faCircleExclamation, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import { useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChronosDoc } from 'types';

import TextInput from '../../../CaseSummary/components/KeyFactsTimeline/components/TextInput';
import { docsQueryKey } from '../../hooks/useFetchDocs';

const DocInfoChip = ({ label, value, loading }: { label: string; value: string; loading: boolean }) => {
  if (loading) {
    return <div className="w-36 bg-gray-300 h-6 animate-pulse rounded-xl"></div>;
  }
  if (value === null) {
    return <></>;
  }
  return (
    <p className="border px-2 rounded text-gray-500 shadow-sm">
      <b>{label}:</b> {value}
    </p>
  );
};

const DocumentRow = ({
  doc,
  caseId,
  page,
  pageSize,
  searchQuery,
}: {
  doc: ChronosDoc;
  caseId: string;
  page: number;
  pageSize: number;
  searchQuery: string;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const handleViewDoc = (doc_id: string, factTotal: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', doc_id);
    searchParams.set('page', '0');
    searchParams.set('factTotal', factTotal.toString());
    navigate(location.pathname + '?' + searchParams.toString());
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  const handleUpdateDoc = () => {
    queryClient.invalidateQueries(docsQueryKey(caseId, page, pageSize, searchQuery));
  };

  return (
    <div key={doc.doc_id} className="flex flex-col border rounded-md py-2 px-4 shadow">
      <div className="flex justify-between items-center">
        <div className="font-semibold w-2/3">
          <TextInput
            editable={true}
            entryId={doc.doc_id}
            entryFieldKey={'fileName'}
            entryFieldValue={doc.file_name}
            endpointToUpdate={`${APIBaseChronos}/client/case/doc/${doc.doc_id}`}
            callback={handleUpdateDoc}
          />
        </div>

        <div className="flex items-center gap-2">
          <span className="text-gray-500 text-sm">{doc.file_extension.toUpperCase()}</span>
          <span className="rounded-sm text-gray-600 bg-brandTertiary bg-opacity-20 px-1 mx-1 text-sm">
            {doc.document_type}
          </span>
        </div>
      </div>
      <div className="text-sm">
        <div className="flex items-center justify-between">
          <div className="flex flex-col text-gray-700 leading-tight w-10/12">
            <div className="flex gap-2 items-center">
              {' '}
              <span className="underline">Author:</span>
              <TextInput
                editable={true}
                entryId={doc.doc_id}
                entryFieldKey={'fileAuthor'}
                entryFieldValue={doc.document_author}
                endpointToUpdate={`${APIBaseChronos}/client/case/doc/${doc.doc_id}`}
                callback={handleUpdateDoc}
              />
            </div>
            <div className="flex gap-2 items-center">
              <span className="underline">Date:</span>
              <TextInput
                editable={true}
                entryId={doc.doc_id}
                entryFieldKey={'document_date_text'}
                entryFieldValue={formatDate(doc.document_date)}
                endpointToUpdate={`${APIBaseChronos}/client/case/doc/${doc.doc_id}`}
                callback={handleUpdateDoc}
              />
            </div>
          </div>

          <div className="flex items-center gap-2 mt-2">
            <DocInfoChip label="Pages" value={doc.n_pages.toString()} loading={false} />
            <DocInfoChip label="Facts" value={doc.facts_count.toString()} loading={false} />
          </div>
        </div>

        <div className="flex justify-between pt-1 pb-1">
          <div className="flex items-center">
            <button
              onClick={() => handleViewDoc(doc.doc_id, doc.facts_count)}
              className="text-gray-100 flex items-center gap-1 bg-buttonPrimary px-2 py-1 rounded-md hover:bg-buttonPrimary-hover"
            >
              <FontAwesomeIcon icon={faEye} />
              View Document
            </button>
          </div>

          <div className="flex flex-col gap-1">
            <div className="text-xs text-gray-700">Uploaded: {formatDate(doc.date_uploaded)}</div>
            <div className="text-xs text-gray-700 flex justify-end">
              {doc.is_processed ? (
                <div className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />
                  <div>Processed</div>
                </div>
              ) : (
                <div className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faCircleExclamation} className="text-red-500" />
                  <div>Not Processed</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentRow;
