import React, { useState, useEffect } from 'react';

import { faLightbulb, faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import Copy from 'components/atoms/Copy';
import Download from 'components/atoms/Download';
import Drawer from 'components/molecules/Drawer';
import InfoModal from 'components/molecules/Modals/Custom';
import { useUserContext } from 'Contexts/User';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { marked } from 'marked';
import { useSearchParams } from 'react-router-dom';
import { ChronosFact, DocumentChunk as Chunk } from 'types';

import ConfidenceChip from './components/ConfidenceChip';
import ConfidenceTooltip from './components/ConfidenceTooltip';
import DocumentChunk from './components/DocumentChunk';
import FactDropdown from '../../CaseSummary/components/KeyFactsTimeline/components/FactDropdown';
import ChunkDocViewer from '../../DocViewer/ChunkDocViewer';
import DocViewer from '../../DocViewer/FactDocViewer';
import useDownloadAnswer from '../hooks/useDownloadAnswer';
import { ConfidenceLevelObject } from '../types';
import { Message, Artifact } from '../types';

interface AnswerProps {
  threadId: string;
  caseId: string;
  messages: Message[] | undefined;
  loading?: boolean;
}

const Answer: React.FC<AnswerProps> = ({ threadId, caseId, loading, messages }) => {
  // State
  const [facts, setFacts] = useState<ChronosFact[]>([]);
  const [updatedFactsData, setUpdatedFactsData] = useState<{ [key: string]: ChronosFact }>({});
  const updateFactData = (eventId: string, data: Partial<ChronosFact>) => {
    setUpdatedFactsData((prev) => ({
      ...prev,
      [eventId]: { ...prev[eventId], ...data },
    }));
  };

  const [artifact, setArtifact] = useState<Artifact | undefined>();

  const [showAllChunks, setShowAllChunks] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [showConfidenceTooltip, setShowConfidenceTooltip] = useState(false);
  const [downloadStart, setDownloadStart] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);

  const { user } = useUserContext();
  const { refetch: fetchDownloadAnswer } = useDownloadAnswer(caseId, threadId, artifact);

  const [searchParams, setSearchParams] = useSearchParams();
  const docId = searchParams.get('docId');
  const docPageNumber = parseInt(searchParams.get('docPageNumber') || '0', 10);
  const eventId = searchParams.get('eventId');
  const chunkId = searchParams.get('chunkId');
  const [selectedChunk, setSelectedChunk] = useState<Chunk | null>(null);
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [isChunkViewerOpen, setIsChunkViewerOpen] = useState(false);
  useEffect(() => {
    if (docId && eventId) {
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId, eventId]);
  useEffect(() => {
    if (docId && chunkId) {
      setIsChunkViewerOpen(true);
    } else {
      setIsChunkViewerOpen(false);
    }
  }, [docId, chunkId]);

  // Handlers
  const handleDownloadAnswer = async () => {
    setDownloadStart(true);
    try {
      await fetchDownloadAnswer();
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      // Reset state
      setDownloadStart(false);
      setDownloadComplete(true);
      setTimeout(() => setDownloadComplete(false), 1000);
    }
  };

  // Effects
  useEffect(() => {
    if (!loading && messages) {
      // Slight delay to make sure content is rendered
      setTimeout(() => setAnimate(true), 50);
    } else {
      setAnimate(false);
    }
  }, [loading, messages]);

  // If there are no facts but there are chunks, show the chunks
  useEffect(() => {
    if (!messages || !messages[1] || !messages[1].facts || !messages[1].chunks) return;
    if (messages[1].facts.length === 0 && messages[1].chunks.length > 0) {
      setShowAllChunks(true);
    }
  }, [messages]);

  useEffect(() => {
    if (messages && messages[1] && messages[1].facts) {
      const modifiedFacts = messages[1].facts.map((fact: ChronosFact) => ({
        ...fact,
        ...updatedFactsData[fact.event_id], // Merge with any updated data
      }));
      setFacts(modifiedFacts);

      // Set artifact if there is one
      setArtifact(undefined);
      if (messages[1].artifacts && messages[1].artifacts.length > 0) {
        setArtifact(messages[1].artifacts[0]);
      }
    }
  }, [messages, updatedFactsData]);

  // Helpers
  const getConfidenceLevel = (trafficLight: string): ConfidenceLevelObject => {
    switch (trafficLight) {
      case 'green':
        return {
          borderColor: 'border-green-500',
          tooltipColor: 'bg-green-500',
          backgroundColor: 'bg-green-50',
          textColor: 'text-green-500',
          text: 'High',
          tooltip:
            'Kim has high confidence in the answer because it has found supporting facts and document text to support it.',
        };
      case 'amber':
        return {
          borderColor: 'border-yellow-500',
          tooltipColor: 'bg-yellow-500',
          backgroundColor: 'bg-yellow-50',
          textColor: 'text-yellow-500',
          text: 'Medium',
          tooltip:
            'Kim has found some supporting information for this answer, but recommends double checking the output and sources.',
        };
      case 'red':
        return {
          borderColor: 'border-red-500',
          tooltipColor: 'bg-red-500',
          backgroundColor: 'bg-red-50',
          textColor: 'text-red-500',
          text: 'Low',
          tooltip:
            'Kim has low confidence in the answer and does not consider this a satisfactory answer. Some supporting information may be present.',
        };
      default:
        return {
          borderColor: 'border-gray-500',
          tooltipColor: 'bg-gray-500',
          backgroundColor: 'bg-gray-50',
          textColor: 'text-gray-500',
          text: 'Unknown',
          tooltip: 'There was a problem generating the answer, so Kim is unable to provide a confidence level.',
        };
    }
  };
  const confidenceLevel = getConfidenceLevel(messages?.[1]?.traffic_light || '');

  const formatAnswer = (
    answer: string,
    references: { [key: number]: { type: string; event_id?: string; chunk_id?: string } },
  ) => {
    if (!answer) return;

    // Check if answer has "-|-" - this is quite hacky for now
    if (answer.includes('-|-')) {
      return formatTable(answer, references);
    }

    const parts = answer.split(/(\[(?:\d+(?:,\s*\d+)*)\]|\*\*.*?\*\*|\n)/);

    return parts.map((part, index) => {
      const referenceMatch = part.match(/^\[(\d+(?:,\s*\d+)*)\]$/);
      const boldMatch = part.match(/^\*\*(.*?)\*\*$/);

      if (referenceMatch) {
        const nums = referenceMatch[1].split(',').map((num) => parseInt(num.trim(), 10));
        return (
          <React.Fragment key={`ref-${index}`}>
            {nums.map((num, i) => {
              const ref = references[num];
              const id = ref?.chunk_id || ref?.event_id || '';
              return (
                <span
                  key={`${index}-${num}`}
                  className={`cursor-pointer ${ref.type === 'chunk' ? 'text-orange-500' : 'text-blue-500'}`}
                  onClick={() => scrollToRow(id, ref.type)}
                >
                  {i === 0 ? '[' : ''}
                  {num}
                  {i === nums.length - 1 ? ']' : ','}
                </span>
              );
            })}
          </React.Fragment>
        );
      } else if (boldMatch) {
        return <strong key={`bold-${index}`}>{boldMatch[1]}</strong>;
      } else if (part === '\n') {
        return <br key={`br-${index}`} />;
      }
      return part;
    });
  };

  const formatTable = (
    answer: string,
    references: { [key: number]: { type: string; event_id?: string; chunk_id?: string } },
  ) => {
    const parsed = marked.parse(answer);

    const options: HTMLReactParserOptions = {
      replace: (domNode: any) => {
        if (domNode.type === 'text') {
          const text = domNode.data;

          const referenceMatch = text.match(/\[(\d+(?:,\s*\d+)*)\]/g);
          if (referenceMatch) {
            const nums = referenceMatch.map((num: string) => parseInt(num.replace(/[[\]]/g, '').trim(), 10));
            return (
              <span className="inline-flex flex-wrap items-center">
                {nums.map((num: number, i: number) => {
                  const ref = references[num];
                  const id = ref?.chunk_id || ref?.event_id || '';
                  return (
                    <span
                      key={`${num}-${i}`}
                      className={`cursor-pointer ${ref?.type === 'chunk' ? 'text-orange-500' : 'text-blue-500'}`}
                      onClick={() => scrollToRow(id, ref?.type || '')}
                    >
                      {i === 0 ? '[' : ''}
                      {num}
                      {i === nums.length - 1 ? ']' : ','}
                    </span>
                  );
                })}
              </span>
            );
          }
        }
      },
    };

    return typeof parsed === 'string' ? parse(parsed, options) : '';
  };

  const scrollToRow = (id: string, type: string) => {
    trackCustomEvent('Kim Reference Clicked');
    if (type === 'chunk') {
      setShowAllChunks(true);
      // Add delay to allow UI to update
      setTimeout(() => {
        scrollToElement(id);
      }, 100);
    } else {
      // Add delay to allow UI to update
      setTimeout(() => {
        scrollToElement(id);
      }, 100);
    }
  };
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('relative', 'z-30', 'animate-pulse-border-green');
      setTimeout(() => {
        element.classList.remove('animate-pulse-border-green', 'z-30', 'relative');
      }, 2000);
    }
  };

  const handleSelectChunk = (chunk: Chunk) => {
    setSelectedChunk(chunk);
    // Add chunkId and docId to URL
    setSearchParams((prev) => {
      prev.set('chunkId', chunk.chunk_id);
      prev.set('docId', chunk.document_id);
      return prev;
    });
  };

  return (
    <div className="h-full overflow-y-scroll py-4 px-4">
      {loading || !messages ? (
        <div className="h-8 w-64 bg-gray-100 rounded-xl animate-pulse mb-3"></div>
      ) : (
        <h1 className="mb-4 font-bold text-lg border-b w-fit pr-8 border-brandSecondary border-opacity-50">
          {messages && messages.length > 0 && messages[0].message_content
            ? messages[0].message_content.charAt(0).toUpperCase() + messages[0].message_content.slice(1)
            : '...'}
        </h1>
      )}
      <div className="flex items-center gap-2 mb-1">
        <FontAwesomeIcon icon={faLightbulb} className="text-brandSecondary pb-1" />
        <h2 className="text-base font-semibold text-gray-800">Answer</h2>
      </div>
      {loading || !messages ? (
        // Loader skeleton
        <div className="border-2 px-4 py-3 rounded shadow h-48 flex flex-col gap-3">
          <div className="h-8 w-full bg-gray-100 rounded-xl animate-pulse"></div>
          <div className="h-8 w-full bg-gray-100 rounded-xl animate-pulse"></div>
          <div className="h-8 w-full bg-gray-100 rounded-xl animate-pulse"></div>
          <div className="h-8 w-4/5 bg-gray-100 rounded-xl animate-pulse"></div>
        </div>
      ) : (
        <div className="border-2 px-4 py-3 rounded shadow">
          <div className="text-sm text-gray-800">
            {formatAnswer(messages?.[1]?.message_content, messages?.[1]?.references)}
          </div>
          <div className="flex mt-4 justify-between">
            <div className="flex gap-2">
              <Copy
                copyHandler={() => {
                  trackCustomEvent('Kim Copy Answer');
                  const cleanedText = messages[1].message_content.replace(/\s*\[[\d,\s]+\]\s*/g, '');
                  navigator.clipboard.writeText(cleanedText);
                }}
              />
              <Download
                handleDownload={handleDownloadAnswer}
                downloadStart={downloadStart}
                downloadComplete={downloadComplete}
              />
            </div>
            <ConfidenceChip
              confidenceLevel={confidenceLevel}
              onClick={() => {
                trackCustomEvent('Kim Confidence Level Clicked');
                setShowConfidenceTooltip(true);
              }}
            />
          </div>
        </div>
      )}

      {artifact ? (
        <></>
      ) : (
        <div className="mt-6">
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faBook} className="text-brandSecondary pb-1" />
            <h2 className="text-base font-semibold text-gray-800">
              {confidenceLevel.text === 'Low' ? 'Possible Information' : 'Supporting Evidence'}
            </h2>
          </div>
          {loading ? (
            <div className="flex flex-col gap-4 mt-2">
              <div className="h-12 bg-gray-100 rounded-2xl animate-pulse"></div>
              <div className="h-12 bg-gray-100 rounded-2xl animate-pulse"></div>
              <div className="h-12 bg-gray-100 rounded-2xl animate-pulse"></div>
              <div className="h-12 bg-gray-100 rounded-2xl animate-pulse"></div>
            </div>
          ) : !messages || !messages[1] || !messages[1].facts || facts.length === 0 ? (
            <div className="mb-2 mt-2 text-gray-600"></div>
          ) : messages[1].facts.length > 0 ? (
            <div className="mb-2 mt-2">
              <div className="flex flex-col gap-2">
                {facts.map((fact, index) => {
                  return (
                    <div
                      className={`border-2 rounded transform transition-all duration-500 ease-out ${
                        animate ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
                      }`}
                      style={{ transitionDelay: `${index * 100}ms` }}
                      key={fact.event_id}
                      id={fact.event_id}
                    >
                      <FactDropdown
                        fact={fact}
                        userId={user?.userId || ''}
                        compressedVersion={true}
                        reference={fact.reference}
                        hideRelevanceIndicator={true}
                        updateFactData={updateFactData}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {loading || !messages ? null : messages[1]?.chunks &&
            Array.isArray(messages[1].chunks) &&
            messages[1].chunks.length > 0 ? (
            <div className="my-2 mt-4 text-xs">
              {messages[1].chunks.length > 0 && (
                <button
                  className="my-1 text-blue-600 hover:text-blue-800 font-medium text-sm"
                  onClick={() => setShowAllChunks(!showAllChunks)}
                >
                  {showAllChunks
                    ? 'Hide additional supporting texts'
                    : `Show ${messages[1].chunks.length} supporting texts...`}
                </button>
              )}
              <div className="flex flex-col gap-2">
                {messages[1].chunks.slice(0, showAllChunks ? undefined : 0).map((chunk) => (
                  <div key={chunk.chunk_id} id={chunk.chunk_id}>
                    <DocumentChunk chunk={chunk} handleSelectChunk={handleSelectChunk} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      )}
      <Drawer
        children={
          <DocViewer docId={docId || ''} caseId={caseId || ''} eventId={eventId || ''} pageNumber={docPageNumber} />
        }
        hideCloseButton={true}
        isOpen={isDocViewerOpen}
        onClose={() => {
          // Remove docId from URL
          setSearchParams((prev) => {
            prev.delete('docId');
            prev.delete('eventId');
            prev.delete('docPageNumber');
            return prev;
          });
        }}
      />
      <Drawer
        children={
          <ChunkDocViewer
            docId={selectedChunk?.document_id || ''}
            coordinates={selectedChunk?.coordinate_details || []}
          />
        }
        hideCloseButton={true}
        isOpen={isChunkViewerOpen}
        onClose={() => {
          setSearchParams((prev) => {
            prev.delete('docId');
            prev.delete('chunkId');
            return prev;
          });
        }}
      />
      <InfoModal
        content={<ConfidenceTooltip confidenceLevel={confidenceLevel} />}
        isOpen={showConfidenceTooltip}
        handleClose={() => setShowConfidenceTooltip(false)}
      />
    </div>
  );
};

export default React.memo(Answer);
